@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

.datetime {
  background-color: red,
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.bloc {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  border: solid grey 1px;
}

.bloc select {
  padding: 10px;
  margin: -5px -20px -5px -5px;
}


/* #select-color {
  border: 2px solid #8898aa;
} */

